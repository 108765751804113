$body-font-family: "Montserrat", Helvetica, Roboto, Arial, sans-serif;
$header-font-family: "Merriweather", serif;



$brandprimary: #44565c;
$brandsecondary: #5d7979;
$brandgreylight: #e0e7e6;
$brandgreylighter: lighten(#e0e7e6, 8%);
$brandgreydark: #595253;
$white: #fff;
$brown: #595253; //#857a7c;

$mobileBreakpoint: 991px;
$desktopBreakpoint: 992px;

@mixin mobile {
  @media (max-width: $mobileBreakpoint) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktopBreakpoint) {
    @content;
  }
}


#responsive-header {
  color: #fff;
  background: #44565c;
  padding: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid $brandgreylight;
}




#responsive-header .logo img {
  width: 170px;
  height: 80px;
  vertical-align: bottom;

  @include mobile {
    width: 105px;
    height: 50px;
  }
}

#headerLinks {
  display: flex;
  align-items: center;
  @include mobile {
    display: none;
  }
}


#headerLinks .button {
  display: inline-block;
  vertical-align: middle;
  margin: 0 0 1rem 0;
  padding: .85em 1em;
  border: 1px solid transparent;
  border-radius: 0;
  transition: background-color .25s ease-out,color .25s ease-out;
  font-family: inherit;
  font-size: .875rem;
  font-weight: bold;
  -webkit-appearance: none;
  line-height: 1;
  text-align: center;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  color: #44565c;
  background: #e0e7e6;
  font-size: 75%;
  vertical-align: middle;
  border: none;
  margin-bottom: 0;
  margin-left: 1rem;

  &:hover {
    background-color: $white;
  }
}