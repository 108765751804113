$body-font-family: "Montserrat", Helvetica, Roboto, Arial, sans-serif;
$header-font-family: "Merriweather", serif;



$brandprimary: #44565c;
$brandsecondary: #5d7979;
$brandgreylight: #e0e7e6;
$brandgreylighter: lighten(#e0e7e6, 8%);
$brandgreydark: #595253;
$white: #fff;
$brown: #595253; //#857a7c;


$mobileBreakpoint: 991px;
$desktopBreakpoint: 992px;

@mixin mobile {
  @media (max-width: $mobileBreakpoint) {
    @content;
  }
}

@mixin desktop {
  @media (min-width: $desktopBreakpoint) {
    @content;
  }
}

$fullWindow: 100%;//$mainWindowWidth + $infoPaneWidth;

$height: 690px;//auto;//625px;//810px;

$infoPaneWidth: 360px;
$timelineLeftPadding: 230px; //250

$sides: $timelineLeftPadding + $infoPaneWidth;

$mainWindowWidth: calc(100% - $sides);

$headerHeight: 90px;

$timelineHeight: 40px;
$mobileTimelineHeight: 40px;


$diff: $infoPaneWidth - $timelineLeftPadding;
$scrollIndicatorPos: calc(35% - ($diff - 55px));//calc(50% - 45px);//(($mainWindowWidth - $timelineLeftPadding) / 2) + $timelineLeftPadding;

//$rightHalf: $mainWindowWidth - $scrollIndicatorPos;

* {
  box-sizing: border-box;
}

html, #root {

  height: 100%;
  width: 100%;  
  
  

  @include mobile {
    width: 100vw;
    height: 100svh; 
    overflow: hidden;
  }

}

body {
  font-family: $body-font-family;
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  //padding: 2rem;
  //scroll-behavior: smooth;

  @include mobile {
    padding: 0;
    height: 100svh;
  }
}

.wrapper {
  border: 2px solid $brandgreylight; 
  overflow: hidden;
  width: 100%;//$fullWindow + 4px; 
  position: relative;

  @include mobile {
    border: none;
    //width: 100vw;
    height: 100%;
  }
}

.overlay {
  background-color: rgba($white, 0.9);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9998;

  @include mobile {
    background-color: rgba($white, 0.9);
  }
  .overlay__inside {
    height: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    max-width: 740px;
    padding: 0 1rem;
    line-height: 1.3;

    @include mobile {
      height: 90%;
    }
    h2 {
      text-decoration: underline;
      color: $brandprimary;
      @include mobile {
        font-size: 1.2rem;
      }
    }
    p {
      margin-bottom: 1rem;
      margin-top: 0;
      padding: 0 1rem;
      color: $brandprimary;

      a {
        color: $brandprimary;
        font-weight: bold;
        text-decoration: underline;
      }

      @include mobile {
        font-size: 0.8rem;
        line-height: 1.3;
      }
    }

    .button {
     
      background-color: $brandprimary;
      color: $white;
      border: none;
      text-decoration: none;
      font-size: 1rem;
      font-weight: bold;
      text-transform: uppercase;
      padding: 1rem 2rem;
      cursor: pointer;
      max-width: 320px;
      margin: 0 auto;
      margin-bottom: 1rem;

      &:hover {
        background-color: darken($brandprimary, 10%);
      }
    }
  }
}

.mobileMenu {
  background-color: rgba($white, 0.6);
  background-color: $brandgreylight;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  transition: all 500ms ease-in-out;

  transform: translate3d(+100%,0%,0);

  display: none;

  @include mobile {
    display: inline-block;
  }


  .mobileMenu__toggle {
    // width: 60px;
    // height: 60px;
    // border-radius: 60px;
    border: 2px solid lighten($brandsecondary, 40%);
    border: none;
    background-color: transparent;
    position: relative;
    background: transparent;
    cursor: pointer;
    position: absolute;
    top:23px;
    left: -117px;
    width: 90px;
    //background-color: $brandgreylight;
    color: $white;
    font-size: 1rem;

    span {
      // display: block;
      // width: 14px;
      // height: 14px;
      // background: transparent;
      // border-top: 3px solid $brandprimary;
      // border-right: 3px solid $brandprimary;
      // box-shadow: 0 0 0 lightgray;
      // transition: all 200ms ease;
      // position: relative;
      // top:7px;
      // left: 28px;//32px;
      // transform: translate3d(0,-50%,0) rotate(-135deg);
    }

    .mobileMenu__toggle__icon {
      display: block;
      width: 20px;
      height: 16px;

      &:after {

        position: absolute;
      top: 2px;
      right: -3px;
      display: block;
      width: 100%;
      width: 20px;
      height: 2px;
      background: #fff;
      box-shadow: 0 7px 0 #fff, 0 14px 0 #fff;
      content: "";
      }
      
      
    }
  }

  &.mobileMenu--open {
    transform: translate3d(0%,0%,0);

    
    
    .mobileMenu__toggle {
      width: 140px;
      color: $brandprimary;

      &:before {
        content: 'Close ';
      }

      left: -2px;

      .mobileMenu__toggle__icon {
     
  
        &:after {
        background: $brandprimary;
        box-shadow: 0 7px 0 $brandprimary, 0 14px 0 $brandprimary;
  
        }
        
        
      }

    
    }
  }


  .mobileMenu__inside {
    height: 100%;
    display: flex;
    margin: 0 auto;
    justify-content: center;
    align-content: center;
    flex-direction: column;
    text-align: center;
    max-width: 340px;
    padding: 0 1rem;
    p {
      margin-bottom: 1rem;
      margin-top: 0;
      padding: 0 1rem;
      color: $brandprimary;
    }

    .button {
      margin-bottom: 1rem;
      background-color: $brandprimary;
      color: $white;
      border: none;
      text-decoration: none;
      font-size: 1rem;
      font-weight: bold;
      text-transform: uppercase;
      padding: 1rem 2rem;
      cursor: pointer;

      &:hover {
        background-color: darken($brandprimary, 10%);
    
      }
    }
  }
}



.timelines-wrap {
  &.timelines-wrap--blurred {
    filter: blur(3px);
  }
  position: relative;
  display: flex;
  width: 100%;//$fullWindow; 
  background-color: $brandgreylighter;
  background: linear-gradient(to bottom, $brandprimary 0 90px, $brandgreylighter 90px 0%),
    repeating-linear-gradient(0deg, $brandsecondary, $brandsecondary 2px, transparent 2px, transparent 6px) repeat;

  @include mobile {
    width: 100vw;
    height: 100%;
    height: calc(100svh - 158px);
    flex-direction: column;

    background: linear-gradient(to bottom, $brandprimary 0 70px, $brandgreylighter 70px 0%),
    repeating-linear-gradient(0deg, $brandsecondary, $brandsecondary 2px, transparent 2px, transparent 6px) repeat;

  }

  .timelines-wrap__title {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 1.4rem;
    font-weight: 600;
    padding: 0.8rem 1rem;
    color: $brandgreylighter;
    z-index: 9999;


    z-index: 9999;
    width: $timelineLeftPadding;
    background: darken($brandprimary, 5%);
    margin-top: 0;
    padding: 31px 1rem;
    height: 90px;



    @include mobile {
      display: none;
    }
  }

  // Timeline title bg
  &:after {
    position: absolute;
    content: "";
    //top: $headerHeight;
    left: 0;
    width: $timelineLeftPadding;
    height: 100%; //calc(100% - $headerHeight);
    background-color: $brandgreylighter;

    background-image: linear-gradient(to bottom, $brandprimary 0 90px, $brandgreylighter 90px 50px);
    z-index: 9;

    box-shadow: 4px 1px 15px -5px rgba(darken($brandprimary, 10%), 0.75);
    -webkit-box-shadow: 4px 1px 15px -5px rgba(darken($brandprimary, 10%), 0.75);
    -moz-box-shadow: 4px 1px 15px -5px rgba(darken($brandprimary, 10%), 0.75);

    @include mobile {
      display: none;
    }
  }
}

.scroll-container {
  width: calc(100% - $infoPaneWidth);
  height: $height;
  //position: relative;

  @include desktop {
    overflow-y: hidden;
  }
  

  @include mobile {
    width: 100vw;
    height: calc(100vh - 100px);
    height: calc(100svh - 158px);
    //overflow-y: hidden;
  }
}

// .timeline-titles {
//   position: relative;
//   top: 90px;
// }

.timelines {
  height: $height;
  width: 2000px;
  background-color: $brandgreylighter;  
  margin-right: 50%;//calc(50% - $timelineLeftPadding / 2 + 5px);//50%;//$infoPaneWidth;//$rightHalf;
  margin-left: calc(35% + $timelineLeftPadding / 2 - 64px);//calc(calc(100% - $timelineLeftPadding) / 2); 55
  //calc(50%;//$timelineLeftPadding;//$scrollIndicatorPos;

  transition: width 0.2s ease-in-out;

  @include mobile {
    position: relative;
    width: 100vw;
    height: calc(100vh - 100px);
    height: calc(100svh - 158px);
    margin-right: 50vw;
    margin-left: 50vw;

  //margin-left: 0;
  }
}

.timelines__wrapper {
  //overflow-y: scroll;

  @include mobile {
    position: relative;
    //top: 90px;
    //padding-top: 90px;
    //overflow-y: scroll;
    height: calc(100vh - 100px);
    height: calc(100svh - 158px);
    //padding-left: 50vw;
  }
}

.timelines__indicator {
  display: flex;
  height: $headerHeight;
  background-color: $brandprimary;

  z-index: 98;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    left: -600px;
    width: 600px;

    height: $headerHeight;
    background-color: $brandprimary;
  }

  &:after {
    content: '';
    position: absolute;

    //$ti_right: calc(65% + 75px);

    //right: calc(-65%);
    //width: calc(65% - 75px);

    height: $headerHeight;
    background-color: $brandprimary;
    //display: none;
    @include mobile {
      display: none;
    }
  }


  @include mobile {
    position: sticky;
    width: 100%;
    z-index: 98;
    top: 0;
    left: 0;
    right: 0;
    //padding-left: 50vw;

    height: 90px;

    &:before {
      content: '';
      position: absolute;
      left: -50vw;
      width: 50vw;
      height: 100%;
      background-color: $brandprimary;
      z-index: 998;
    }
  }

  .indicator {
    border-left: 2px solid rgba($brandgreylighter, 0.7);
    display: flex;
    position: relative;

    @include mobile {
      z-index: 999;
    }

    .indicator__inside {
      background-color: $brandprimary;
      color: rgba($brandgreylighter, 0.7);
      //opacity: 0.8;
      font-size: 14px;
      align-self: center;
      position: absolute;
      left: -28px;
      top: 32px;

      @include mobile {
        font-size: 12px;
        left: -28px;
        top: 28px;
      }
    }
  }
}

.scrollIndicator {
  position: absolute;
  z-index: 1000;
  left: $scrollIndicatorPos;//calc(50%);// + $timelineLeftPadding / 2);//calc(50% - 27px);//$mainWindowWidth - $timelineLeftPadding - 27;

  @include mobile {
    left: 50%;
  }

  &:before {
    content: "";
    position: absolute;
    width: 2px;
    height: $height;
    background-color: lighten($brandsecondary, 40%);
    background-color: $brandprimary;
    background-color: #595253;
    width: 2px;
    //left: $scrollIndicatorPos;//$timelineLeftPadding;
    z-index: 998;

    box-shadow: 0px 1px 2px 1px rgba(lighten($brandprimary, 10%), 0.2);
    -webkit-box-shadow: 0px 1px 2px 1px rgba(lighten($brandprimary, 10%), 0.2);
    -moz-box-shadow: 0px 1px 2px 1px rgba(lighten($brandprimary, 10%), 0.2);
  

    @include mobile {
      height: 100vh;
    }
  }

  .scrollIndicator__currentTime {
    background-color: $brandgreylighter;
    padding: 0.4rem 0.6rem;
  
    border-radius: 8px;//14px;
    top: 14px;
    position: relative;
    left: -50px; //left: $scrollIndicatorPos - 50px; //(($mainWindowWidth - $timelineLeftPadding) / 2) + $timelineLeftPadding - 50px;//$timelineLeftPadding - 50px;
    z-index: 999;
    font-size: 0.9rem;
    font-weight: 600;
    min-width: 104px;
    text-align: center;
    color: $brandprimary;

    display: flex;
    flex-direction: column;

    border: 2px solid rgba($brown,1);
    -webkit-box-shadow: 0px 0px 3px 2px rgba(lighten($brandprimary, 20%), 0.6);

    @include mobile {
      top: 10px;
    }

    .scrollIndicator__currentTime__date {
      font-size: 0.8rem;
      color: $brandsecondary;
    }
  }

  &.scrollIndicator--inactive {
    //opacity: 0.6;
    .scrollIndicator__currentTime {
    border: 2px solid rgba(lighten($brandprimary, 10%), 0.4);
    }
    &:before {
      background-color: lighten($brandsecondary, 40%);
      box-shadow: none;
    }
  }
}

.timeline {
  width: 100%;
  height: $timelineHeight;
  border-bottom: 2px solid lighten($brandsecondary, 40%);

  // &:after {
  //   left: 0;
  //   //position: absolute;
  //   content: 'hi';
  // }


  &:after {
    content: '';
    width: 4000px;
    height: 100%;
    position: absolute;
    left: 0px;
    //top: 0;

    height: 40px; // how many lines to show

    background: repeating-linear-gradient( 0deg, lighten($brandsecondary, 30%), lighten($brandsecondary, 30%) 2px, transparent 2px, transparent 40px );
    pointer-events: none;

    @include mobile {
      left: -50vw;
      width: calc(2400px + 100vw);
      
    }
  }

  //position: relative;
  
  &:before {
    // content: '';
    // width: 4000px;
    // height: 100%;
    // position: absolute;
    // left: 0px;
    // top: $headerHeight;

    // height: 100px; // how many lines to show

    // background: repeating-linear-gradient( 0deg, lighten($brandsecondary, 30%), lighten($brandsecondary, 30%) 2px, transparent 2px, transparent 50px );

    @include mobile {
      top: 38px;
      background: repeating-linear-gradient( 0deg, lighten($brandsecondary, 40%), lighten($brandsecondary, 40%) 2px, transparent 2px, transparent 50px );
      //background: none;
      background: lighten($brandsecondary, 40%);
      height: 2px;
      left: -900px;
      //width: 140%;
      right: -900px;
    }
    
  }  
  
  @include mobile {
    position: relative;
    height: $mobileTimelineHeight;
  }

}

.timeline__title {
  position: absolute;
  
  color: rgba($brandprimary, 0.8);
  font-size: 0.8rem;
  font-weight: 600;
  max-width: $timelineLeftPadding;
  width: $timelineLeftPadding;
  line-height: 1;//$timelineHeight;
  padding: 0.3rem 1rem;
  padding-left: 1rem;
  padding-right: 0.2rem;
  height: $timelineHeight;
  border-bottom: 2px solid lighten($brandsecondary, 30%);

  background-color: $brandgreylighter;
  z-index: 96;

  display: flex;
  align-items: center;

  

  // &:after {
  //   content: '';
  //   width: 4000px;
  //   height: 100%;
  //   position: absolute;
  //   left: 0px;
  //   top: 0;

  //   height: 40px; // how many lines to show

  //   background: repeating-linear-gradient( 0deg, lighten($brandsecondary, 30%), lighten($brandsecondary, 30%) 2px, transparent 2px, transparent 40px );
  //   pointer-events: none;
  // }



  //transition: padding 0.2s ease-in-out;

  @include desktop {
     left: 0 !important;

     &:hover {
      cursor: pointer;
      color: rgba($brandprimary, 1);
     }
    //left: calc(-50%) !important;
  }

  &.timeline__title--active {
    color: rgba($brandprimary, 1);
    padding-left: 1.2rem;
    &:before {
      content: '';
      height: 100%;
      width: 10px;
      position: absolute;
      left: 0;
      top: 0;
      opacity: 0.8;
      background-color: $brandsecondary;
    }
  }


  @include mobile {
    //border-bottom: 0;

    border-bottom: none;//2px solid lighten($brandsecondary, 40%);

    left: -50vw;
    width: 180px;
    font-size: 0.8rem;
    padding: 0 0.4rem;
    background-color: transparent;
    height: $mobileTimelineHeight;
    pointer-events: none;
    text-shadow: $white 2px 1px 2px;

    div {
      transition: opacity 0.05s ease-in-out;
    }

    &.timeline__title--active {
      color: rgba($brandprimary, 1);
      padding-left: 0.4rem;
      &:before {
        display: none;
      }
    }


  }
}

// TODO
@include mobile {
  .is-scrolling .timeline__title {
    opacity: 0;
  }
}

.timeline__events {
  //padding-left: $scrollIndicatorPos;//$timelineLeftPadding;
  //padding-right: $rightHalf;
}

.timeline__events__inner {
  position: relative;
}

.event {
  position: absolute;
  top: 0;
  background-color: rgba($brandsecondary, 0.2);
  border-left: 2px solid rgba($brandsecondary, 0.3);
  border-right: 2px solid rgba($brandsecondary, 0.3);
  height: $timelineHeight;
  z-index: 56;//2;

  min-width: 20px;

  @include mobile {
    height: $mobileTimelineHeight;
  }

  &:before {
    content: '';
    position: absolute;
    top: -1px;
    left: -4px;
    width: 0; 
    height: 0; 
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-bottom: 5px solid rgba($brandsecondary, 0.8);
    transform: rotate(-45deg);
  }

  &--active {
    opacity: 0.8;
    border: 3px solid $brown;
    z-index: 55;//99;//1;
    top: -2px;
    height: $timelineHeight + 2px;

    @include mobile {
      height: $mobileTimelineHeight + 2px;
    }

    &:before {
      content: '';
      position: absolute;
      top: -2px;
      left: -5px;
      width: 0; 
      height: 0; 
      border-left: 5px solid transparent;
      border-right: 5px solid transparent;
      border-bottom: 5px solid $brown;
      transform: rotate(-45deg);
    }
  }

  &--estimate {
    background-color: transparent;
    opacity: 1;
    border-left: 2px solid rgba($brandsecondary, 0.3);
    border-right: 2px solid rgba($brandsecondary, 0.3);
    background: repeating-linear-gradient(
      -45deg,
      rgba($brandsecondary, 0.2),
      rgba($brandsecondary, 0.2) 3px,
      transparent 3px,
      transparent 6px
    );

    &.event--active {
      opacity: 0.8;
      // background-color: $brown;
      border: 3px solid $brown;
      //border: 1px solid $brown; //rgba($brown, 0.2)
    //   background: repeating-linear-gradient(-45deg, rgba($brown, 1), rgba($brown, 1) 2px, transparent 2px, transparent 6px);
    }
  }
}

// Info pane

.infoPane {
  width: $infoPaneWidth;
  border-left: 2px solid lighten($brandsecondary, 40%);
  position: relative;
  //right: -360px;
  //position: absolute;
  height: $height;
  padding: 2.6rem 1rem 0rem 1.6rem;

  color: $brandprimary;
  background-color: $white;

  z-index: 99;
  //overflow-y: scroll;

  &.infoPane--full {

    &:after {

      //content: '';
      width: 304px;
      height:60px;
      position: absolute;
      background: rgb(255,255,255);
      background: linear-gradient(0deg, rgba(255,255,255,1) 0%, rgba(255,255,255,0) 100%);
      bottom: 95px;
      border-bottom: 2px solid lighten($brandsecondary, 40%);

      @include mobile {
        display: none;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 12px;
  }

  ::-webkit-scrollbar-track {
      background: rgba($brandsecondary, 0);
      border-radius: 0px;
  }

  &.infoPane--full {
    ::-webkit-scrollbar-track {
      background: rgba($brandsecondary, .2);
      border-radius: 0px;
    }
  }

  ::-webkit-scrollbar-thumb {
      background: rgba($brandprimary, 0.6);
    }

  ::-webkit-scrollbar-thumb:hover {
      background: rgba($brandprimary, 0.8);
    }

  ::-webkit-scrollbar-thumb:active {
    background: rgba($brandprimary, 1);
  }


  @include mobile {
    width: 100vw;
    height: calc(100svh - 138px);//200px;
    position: absolute;
    //top: 30px;
    z-index: 9991;

    overflow-y: initial;
    padding-top: 1.4rem;

    transition: all 500ms ease-in-out;
    transform: translate3d(0%,calc(100vh - 206px),0);//TODO
    transform: translate3d(0%,calc(100svh - 160px),0);

    padding: 2rem 1rem 0rem 1.8rem;

  }

  &.infoPane--open {

    transform: translate3d(-0%,0%,0);
    
  }

  .infoPane__expand {
    width: 40px;
    height: 30px;
    //border-radius: 40px;

    border: 2px solid lighten($brandprimary, 10%);
 
    background-color: $white;
    position: relative;
    cursor: pointer;
    position: absolute;
    top:8px;
    right: 8px;
    background: white;
    
    display: none;

    z-index: 9980;

    @include mobile {
      display: inline-block;
    }

    span {
      display: inline-block;
      width: 12px;
      height: 12px;
      background: transparent;
      border-top: 3px solid $brandprimary;
      border-right: 3px solid $brandprimary;
      box-shadow: 0 0 0 lightgray;
      transition: all 200ms ease;
      margin-top: 14px;
      // top:7px;
      // left: 2px;//7px;
      transform: translate3d(0,-50%,0) rotate(-45deg);
      -webkit-transform: translate3d(0,-49%,0) rotate(-45deg);
      transform-origin: 6px 6px;
      


    }

    .infoPane__toggle__icon {

    }
  }


  .infoPane__inner {
    //height: 100%;
    height: calc(100% - 95px);
    overflow-y: scroll;
    padding-right: 1rem;
    padding-bottom: 3rem;
    position: relative;

    ul {
      font-size: 0.9rem;
     //margin: 0;
      padding-left: 20px;
      li {
        margin-bottom: 1em;
      }
    }

 

    @include mobile {
      padding-bottom: 6rem;
      overflow-y: scroll;
      //height: 110px;
    }
  }

  @include mobile {
    &.infoPane--full {
      padding-top: 1.6rem;
      .infoPane__inner {
      
        height: 170px;
      }
    }
  }


  &.infoPane--open {
    transform: translate3d(0%,0%,0);
    transform: translate3d(-0%,91px,0);
    -webkit-transform: translate3d(-0%,91px,0);
    
    .infoPane__expand {
      //top:-1px;
      //right: calc(50% - 20px);
      span {
        margin-top: 10px;
        transform: translate3d(0,-50%,0) rotate(-225deg);
        // top:4px;
        // left: -2px;//7px;
      }
    }

    .infoPane__inner {
      @include mobile {
        height: calc(100vh - 360px);
        height: calc(100svh - 200px);
      }
    }
  }


  h2 {
    margin-top: 0;
    margin-bottom: 0.2rem;
    font-size: 1.4rem;
    line-height: 1.1;

    @include mobile {
      font-size: 1.2rem;
    }
  }

  h3.infoPane__activeTime {
    color: lighten($brandsecondary, 10%);
    font-size: 1.3rem;
    margin-top: 0;
    margin-bottom: 0;

    @include mobile {
      font-size: 1.2rem;
    }
  }

  h3.infoPane__timelineTitle {
    color: $brandprimary;
    font-size: 1rem;
    margin-top: 1.6rem;
    margin-bottom: 0.4rem;
  }

  .infoPane__document {
    display: inline-block;
    margin-top: 1.2rem;
    border-top: 2px solid lighten($brandsecondary, 40%);
    text-decoration: none ;
    position: relative;

    h3 {
      font-size: 1rem;
      margin-top: 1rem;
      margin-bottom: 0rem;
      color: $brandprimary;

      &:after {
        display: inline-block;
        content: '';
        margin-left: 5px;
        width: 14px;
        height: 14px;
        background-image: url(./external-link.svg);
        color: $brandprimary;
      }
    }

    p {
      a {
        color: $brandprimary;
        font-weight: bold;
        text-decoration: underline;
      }
      margin-top: 0.4rem;
      color: $brandprimary;
    }

    a:hover {
      color: darken($brandprimary, 20%);
    }

  }

  .infoPane__clickableEvent {
    cursor: pointer;
    position: relative;
    padding-right: 20px;

    .infoPane__clickableEvent__title:after {
      //content: ' >';
    }

    .infoPane__clickableEvent__icon {
      position: absolute;
      top: 18px;
      right: 5px;//-10px;
      transition: all 200ms ease;
      span {
        display: block;
        width: 8px;
        height: 8px;
        background: transparent;
        border-top: 2px solid $brandprimary;
        border-right: 2px solid $brandprimary;
        box-shadow: 0 0 0 lightgray;
        transition: all 200ms ease;
  
        transform: translate3d(0,-50%,0) rotate(45deg);
      }
    }

    &:hover,
    &:focus {
      .infoPane__clickableEvent__title {
        color: darken($brandprimary, 20%)
      }
      .infoPane__clickableEvent__icon {
        transform: translate3d(3px,0,0);
      }
    }
  }

  .infoPane__description {
    font-size: 0.9rem;
  }

  p {
    font-size: 0.9rem;
    margin-bottom: 0;
  }

  p.infoPane__date {
    margin-top: 0;
    font-size: 0.8rem;
    color: lighten($brandsecondary, 10%);
    margin-bottom: 0.6rem;
    &.infoPane__date--bold {
      font-weight: bold;

    }
  }

  p.infoPane__location {
    margin-top: 0;
    color: $brandprimary;
    font-size: 1rem;
    font-weight: bold;
  }

  .bottomNav {
    // position: absolute;
    // bottom: 0;
    //position: fixed;
    width: 96%;
    z-index: 990;
    height: 50px;
    background-color: $white;
    display: flex;
    align-items: center;
   justify-content: space-between;

    //background-color: rgba(#5d7979, 0.1);

    //border-top: 2px solid lighten($brandsecondary, 40%);
    margin-top: 2rem;
    padding-top: 0.4rem;
    

    margin: 1rem -2.2rem 1rem 0rem;
    padding: 0.2rem 1rem 0.2rem 0rem;

   //border-bottom: 2px solid #cbd7d7;

    h3 {
     // line-height: 65px;
      position: relative;
      font-size: 0.9rem;
      color: $brandprimary; //lighten($brandsecondary, 10%);
      font-weight: 600;
      padding-right: 0.6rem;
      //padding-bottom: 1.1rem;
      span {
        font-size: 0.9rem;
        padding-left: 0.4rem;
        // position: absolute;
        font-weight: 300;
        // bottom: 0;
        // left: 0;
      }
    }

    @include mobile {
      margin-top: 0;
      top: 135px;
      height: 70px;
      bottom: auto;
      padding: 0.4rem 2.6rem 0.8rem 0rem;

      h3 {
        line-height: 1;
      }
    }

  }

  &.infoPane--open {
    .bottomNav {
      @include mobile {
        top:auto;
        height: 70px;
        bottom: 0;
      }
    }
  }

  .navButtons {
    //position: absolute;
    //bottom: 1rem;
    min-width: 70px;
    //right: 1rem;
    //padding-left: 1rem;
    //padding-bottom: 1rem;

    @include mobile {
      right: 1rem;
      bottom: 0;
    }

    button {
      width: 30px;
      height: 30px;
      border-radius: 30px;
      border: 2px solid lighten($brandsecondary, 40%);
      background-color: $white;
      position: relative;
      background: transparent;
      cursor: pointer;

      span {
        display: block;
        width: 10px;
        height: 10px;
        background: transparent;
        border-top: 3px solid $brandprimary;
        border-right: 3px solid $brandprimary;
        box-shadow: 0 0 0 lightgray;
        transition: all 200ms ease;
        position: absolute;
        top: 13px;
      }

      &:disabled {
        opacity: 0.2;
      }

      &:hover {
        background-color: lighten($brandsecondary, 40%);

        span {
          border-top: 3px solid $white;
          border-right: 3px solid $white;
        }
      }
    }
    .navButtons__previous {
      margin-right: 0.4rem;
      .navButtons__previous__icon {
        left: 10px;
        transform: translate3d(0,-50%,0) rotate(-135deg);
      }
    }
    .navButtons__next {

      .navButtons__next__icon {
        right: 10px;
        transform: translate3d(0,-50%,0) rotate(45deg);
      }
    }
  }
}

// Zoom

.zoomControls {
  position: absolute;
  z-index: 9990;
  bottom: 18px;
  right: $infoPaneWidth + 20px;
  background-color: #fff;
  height: 36px;
  border-radius: 18px;
  padding: 0 4px;
  border: 2px solid lighten($brandsecondary, 40%); 


  

  box-shadow: 4px 1px 10px -5px rgba(darken($brandprimary, 10%), 0.55);
  -webkit-box-shadow: 4px 1px 10px -5px rgba(darken($brandprimary, 10%), 0.55);
  -moz-box-shadow: 4px 1px 10px -5px rgba(darken($brandprimary, 10%), 0.55);

  //right: 0;

  @include mobile {
    bottom: 310px;
    right: 10px;
  }

  &:after {
    content: '';
    position: absolute;
    left: calc(50% - 2px);
    top: 0;
    width: 2px;
    height: 34px;
    background: lighten($brandsecondary, 40%);
  }

  button {
    display: inline-block;
    background-color: transparent;
    border: none;
     font-size: 1.6rem;
     height: 36px;
    cursor: pointer;
    position: relative;
    top: -2px;
    padding: 0 8px;
    line-height: 1;

    svg {
      display: block;
      height: 18px;
      width: 18px;
      fill:$brandsecondary;//#44565c;
    }

    &:disabled {
      svg {
        opacity: 0.4;
      }
    }

    &:hover {
      svg {
      fill:$brandprimary;
      }
    }
  }

  

  .zoomControls__out {
    position: relative;
    display: inline-block;
  }

  .zoomControls__in {
    position: relative;
  }
}

// Debug

.debugBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  //background-color: #fff;
  width: 100%;
  padding: 2rem;
}

// Display a cross with CSS only.
//
// $size  : px or em
// $color : color
// $thickness : px
@mixin cross($size: 20px, $color: currentColor, $thickness: 1px) {
  margin: 0;
  padding: 0;
  border: 0;
  background: none;
  position: relative;
  width: $size;
  height: $size;

  &:before,
  &:after {
    content: "";
    position: absolute;
    top: ($size - $thickness) / 2;
    left: 0;
    right: 0;
    height: $thickness;
    background: $color;
    border-radius: $thickness;
  }

  &:before {
    transform: rotate(45deg);
  }

  &:after {
    transform: rotate(-45deg);
  }

  span {
    display: block;
  }
}

.infoPane__closeButton {
  margin: 0;
  border: 0;
  padding: 0;
  display: flex;
  //flex-flow: column nowrap;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
  //transition: all 150ms;
  background: none;
  padding: 0.5rem 0.2rem;

  position: absolute;
  left: 0px;
  top: 0px;
  right: 0;
  

  &:hover {
    background-color: #cbd7d7;
  }


  border-bottom: 2px solid #cbd7d7;

  @include mobile {
    right: 10px;
    top: 6px;
    border: none;
    position: absolute;
    max-width: 200px;
  }

  color: #44565c;
  font-size: 0.9rem;
  font-weight: 600;

  .infoPane__closeButton__icon {
    //@include cross(22px, $brandprimary, 3px);
    position: relative;
    width: 20px;
    margin-right: 0.4rem;
    &:before {
      content: '';
      display: block;
        width: 8px;
        height: 8px;
        background: transparent;
        border-top: 3px solid $brandprimary;
        border-right: 3px solid $brandprimary;
        box-shadow: 0 0 0 lightgray;
        transition: all 200ms ease;
        position: absolute;
        //top: 13px;
        left: 10px;
        transform: translate3d(0,-50%,0) rotate(-135deg);
    }

  }

  &:hover,
  &:focus {
    //transform: scale(1.2);
  }
}

.scrollBar {

  
  width: calc(100% - $timelineLeftPadding - $infoPaneWidth - 1px);
  left: $timelineLeftPadding;
  height: 20px;

  background-color: $brandsecondary;
  position: absolute;
  top: 70px;
  z-index: 999;
  opacity: 1;
  border-bottom: 1px solid $brandprimary;

  @include mobile {
    //display: none;
    width: 100%;
    left: 0;
    height: 20px;
    top: 70px;
  }

  .scrollBar__left, .scrollBar__right {
    height: 19px;
    width: 20px;
    background-color: rgba($white, 0.5);

    &:hover {
      background-color: rgba($white, 0.8);
    }
  }

  .scrollBar__left {
    &:after {
      //content: '⇨';
      content: '';
      display: block;
      position: relative;
      left:7px;
      top: 5px;
      color: $brandprimary;
      width: 0; 
      height: 0; 
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      
      border-right: 5px solid $brandsecondary;
    }
  }



  .scrollBar__right {
    position: absolute;
    right: 0px;
    top: 0;
    &:after {
      //content: '⇨';
      content: '';
      display: block;
      position: relative;
      left:8px;
      top: 5px;
      color: $brandprimary;
      width: 0; 
      height: 0; 
      border-top: 5px solid transparent;
      border-bottom: 5px solid transparent;
      
      border-left: 5px solid $brandsecondary;
    }
  }

  .scrollBar__handle {
    position: absolute;
    width: 100px;
    height: 24px;
    opacity: 1;
    border: 1px solid $brandprimary;
    background-color: rgba($white, 0.9);
    border-radius: 3px;
    top: -3px;
    cursor: grab;
    z-index: 9999;
    
    box-shadow: 4px 1px 15px -5px rgba(darken($brandprimary, 10%), 0.75);
    -webkit-box-shadow: 4px 1px 15px -5px rgba(darken($brandprimary, 10%), 0.75);
    -moz-box-shadow: 4px 1px 15px -5px rgba(darken($brandprimary, 10%), 0.75);

    &:before {
      content: '';
      background-image: url("data:image/svg+xml,%3Csvg width='17px' height='13px' viewBox='0 0 17 13' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'%3E%3Cg id='Page-1' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'%3E%3Cg id='Group' fill='%2344565C'%3E%3Crect id='Rectangle' x='0' y='0' width='2.125' height='13'%3E%3C/rect%3E%3Crect id='Rectangle' x='7.4375' y='0' width='2.125' height='13'%3E%3C/rect%3E%3Crect id='Rectangle' x='14.875' y='0' width='2.125' height='13'%3E%3C/rect%3E%3C/g%3E%3C/g%3E%3C/svg%3E");
      height: 13px;
      width: 17px;
      display: block;
      position: relative;
      left: 41px;
      top: 4px;
    }

    @include mobile {
      height: 24px;
    }

    &:hover {
      background-color: rgba($white, 1);
    }
  }
}

.scrollTimeline {
  position: absolute;
  left: 190px;
  
  width: 24px;
  height: 24px;
  border-radius: 24px;
  background-color: $white;
  z-index: 9999;

  color: $brandprimary;
  padding: 3px;

  cursor: pointer;

  @include mobile {
    display: none;
  }
  &:hover {
    //background-color: darken($brandprimary, 5%);
    opacity: 0.8;
  }

  svg {

    width: 18px;
    height: 18px;

  }

  &.scrollTimeline--up {
    top: 18px;
    background-image: url(./scroll-up.svg);
    background-size: 18px 18px;
    background-position: 3px 3px;
    background-repeat: no-repeat;
  }

  &.scrollTimeline--down {
    top: 47px;
    background-image: url(./scroll-down.svg);
    background-size: 18px 18px;
    background-position: 3px 3px;
    background-repeat: no-repeat;
  }

  &.scrollTimeline--hide {
    opacity: 0.2;
  }
}
